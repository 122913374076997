import React, { useEffect, useState } from "react";
import { fetchRuntimeFormData, getToken } from "./Apirequest";
import CustomTable from "./components/CustomTable";
import * as XLSX from "xlsx";
import { Box, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormPreview from "./FormPreview";

const Columns: any = [
  {
    id: "name",
    label: "Name",
    align: "center",
    minWidth: 120,
  },
  {
    id: "email",
    label: "Email",
    align: "center",
    minWidth: 120,
  },
];

const Results = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const fetchFormData = async (id) => {
    try {
      const token = await getToken();
      const result = await fetchRuntimeFormData(id, token);
      if (result?.success) {
        const formattedData = result?.data?.content?.map((item) => {
          return item?.formData;
        });
        setData(formattedData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = () => {};
  const handleDel = () => {};
  // const preview = (row) => {
  //   // console.log(row, "row");
  //   navigate("/preview", { state: { data: row } });
  // };
  const preview = (row) => {
    setSelectedRow(row); // Set the selected row data
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const handleDownload = () => {
    try {
      // Hardcoded data based on your example
      // const data = [
      //   {
      //     name: "vinod",
      //     email: "vinod.g@techsophy.com",
      //     phone: "8973779938",
      //     DoYouCurrentlyUseAnyPrescriptionMedicationOnDailyBasis: "yes",
      //     ifYesPleaseEnterMedicineDoseAndBrand: [
      //       {
      //         medicine: "Aspirin",
      //         dose: "600mg",
      //         brand: "Cipla",
      //         number: "60",
      //         type: "tab",
      //       },
      //       {
      //         medicine: "Dolo",
      //         dose: "250mg",
      //         brand: "Tanveer",
      //         number: "70",
      //         type: "tab",
      //       },
      //       {
      //         medicine: "Citizen",
      //         dose: "60mg",
      //         brand: "Jah",
      //         number: "10",
      //         type: "syrup",
      //       },
      //     ],
      //     DoYouCurrentlyUseAnyPrescriptionMedicationOnDailyBasis1: "yes",
      //     ifYesPleaseEnterMedicineDoseAndBrand1: [
      //       {
      //         medicine: "Antibiotic",
      //         dose: "200mg",
      //         brand: "DrReddy",
      //         number: "Cipla",
      //         type: "tab",
      //       },
      //       {
      //         medicine: "fff",
      //         dose: "200mg",
      //         brand: "DrReddy",
      //         number: "Cipla",
      //         type: "tab",
      //       },
      //       {
      //         medicine: "ggg",
      //         dose: "200mg",
      //         brand: "DrReddy",
      //         number: "Cipla",
      //         type: "tab",
      //       },
      //       {
      //         medicine: "hhh",
      //         dose: "200mg",
      //         brand: "DrReddy",
      //         number: "Cipla",
      //         type: "tab",
      //       },
      //     ],
      //   },
      // ];

      // Process rows and flatten nested objects
      const processRow = (row) => {
        const repetitiveMedications =
          row.ifYesPleaseEnterMedicineDoseAndBrand || [];
        const oneTimeMedications =
          row.ifYesPleaseEnterMedicineDoseAndBrand1 || [];
        const baseRow = {
          Name: row.name || "",
          Email: row.email || "",
          Phone: row.phone || "",
        };

        const rows: any = [];

        // Map repetitive medications
        repetitiveMedications.forEach((med) => {
          rows.push({
            ...baseRow,
            "Medicine/Product (Repetitive)": med.medicine || "",
            "Dose (Repetitive)": med.dose || "",
            "Brand (Repetitive)": med.brand || "",
            "Quantity (Repetitive)": med.number || "",
            "Type (Repetitive)": med.type || "",
          });
        });

        // Map one-time medications
        oneTimeMedications.forEach((med, index) => {
          const rowWithOneTime = rows[index] || { ...baseRow }; // Align to repetitive rows
          rowWithOneTime["Medicine/Product (One-Time)"] = med.medicine || "";
          rowWithOneTime["Dose (One-Time)"] = med.dose || "";
          rowWithOneTime["Brand (One-Time)"] = med.brand || "";
          rowWithOneTime["Quantity (One-Time)"] = med.number || "";
          rowWithOneTime["Type (One-Time)"] = med.type || "";
          rows[index] = rowWithOneTime;
        });

        return rows;
      };

      // Flatten data
      const processedData = data.flatMap((row) => processRow(row));

      // Define table headings
      const headings = [
        "Name",
        "Email",
        "Phone",
        "Medicine/Product (Repetitive)",
        "Dose (Repetitive)",
        "Brand (Repetitive)",
        "Quantity (Repetitive)",
        "Type (Repetitive)",
        "Medicine/Product (One-Time)",
        "Dose (One-Time)",
        "Brand (One-Time)",
        "Quantity (One-Time)",
        "Type (One-Time)",
      ];

      // Format data for Excel
      const worksheetData = [
        headings,
        ...processedData.map((row) => {
          return headings.map((key) => row[key] || "");
        }),
      ];

      // Create a worksheet and workbook
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Results");

      // Trigger file download
      XLSX.writeFile(workbook, "Results.xlsx");
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  useEffect(() => {
    fetchFormData(process.env.REACT_APP_FORM_ID);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: "10%",
      }}
    >
      <CustomTable
        columns={
          // data.length > 0
          //   ? Object.keys(data[0]).map((key) => ({
          //       id: key,
          //       label: key,
          //       align: "center",
          //     }))
          //   : []
          Columns
        }
        data={data}
        isEdit={false}
        isDelete={false}
        handleEdit={handleEdit}
        handleDelete={handleDel}
        isView={true}
        handleView={preview}
      />
      {data.length > 0 && (
        <Button
          variant="outlined"
          onClick={handleDownload}
          style={{ width: "20%", marginTop: "20px" }}
        >
          Download Excel
        </Button>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="preview-modal-title"
        aria-describedby="preview-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
            borderRadius: 2,
            width: "90%",
            maxHeight: "80%",
            overflowY: "auto",
          }}
        >
          {selectedRow && (
            <FormPreview rowData={selectedRow} handleClose={handleCloseModal} />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Results;

import { PUSH_NOTIFICATION } from "../constants/actions";
import COMMON from "../constants/common";
import {
  ACCESS_TOEKN,
  LOADER,
  PROPOSAL_DATA,
  ROUTES_FILTERED,
  USER_GROUP,
  PROPOSAL_CART,
  USER_ID,
  PROPOSAL_CREATED_ID,
} from "./actions";

// Reducer

export interface NotificationProps {
  isOpen: boolean;
  type?: "error" | "info" | "success" | "warning";
  message?: string;
  handleClose?: () => void;
}
const initialState = {
  isLoading: false,
  notification: {},
  isAnonymous: true,
  access_token: "",
  proposalData: {},
  filteredRoutes: [],
  userGroup: "",
  proposalCart: [],
  userId: "",
  proposalCreatedId: "",
};

export const loadingActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case PUSH_NOTIFICATION:
      return { ...state, notification: action.payload };

    case COMMON.ANONYMOUS:
      return {
        ...state,
        isAnonymous: action.payload,
      };

    case ACCESS_TOEKN:
      return {
        ...state,
        access_token: action.payload,
      };

    case PROPOSAL_DATA:
      return {
        ...state,
        proposalData: action.payload,
      };

    case ROUTES_FILTERED:
      return {
        ...state,
        filteredRoutes: action.payload,
      };

    case USER_GROUP:
      return {
        ...state,
        userGroup: action.payload,
      };

    case PROPOSAL_CART:
      return {
        ...state,
        proposalCart: action.payload,
      };

    case USER_ID:
      return {
        ...state,
        userId: action.payload,
      };

    case PROPOSAL_CREATED_ID:
      return {
        ...state,
        proposalCreatedId: action.payload,
      };

    default:
      return state;
  }
};

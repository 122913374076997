import axios from "axios";

const apiUrl = `${process.env.REACT_APP_AUTH_URL}/auth/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/token`;

export const getToken = async () => {
  try {
    const credentials = {
      username: `${process.env.REACT_APP_USERNAME}`,
      password: `${process.env.REACT_APP_PASSWORD}`,
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
      grant_type: "password",
    };

    const response = await axios.post(
      apiUrl,
      new URLSearchParams(credentials),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response?.data?.access_token;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const fetchRuntimeForm = async (id, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_GATEWAY_URL}/form-runtime/v1/forms/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      const data = response.data.data;
      return {
        success: response.data.success,
        data,
        message: response.data.message,
      };
    }

    return { success: false, message: response.data.message };
  } catch (error: any) {
    console.error("Error:", error.message);
    return { success: false, message: "Failed to fetch runtime form data" };
  }
};

export const postRuntimeFormData = async (
  body: {
    formId: string;
    id?: string;
    formData: object;
  },
  token
) => {
  const r: any = (await axios.post(
    `${process.env.REACT_APP_GATEWAY_URL}/form-runtime/v1/form-data`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )) as any;

  if (r.success) {
    return { success: r.success, data: r.data, message: r.message };
  }

  return { success: false, data: r.data, message: r.message };
};

export const fetchRuntimeFormData = async (id, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_GATEWAY_URL}/form-runtime/v1/form-data?formId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      const data = response.data.data;
      return {
        success: response.data.success,
        data,
        message: response.data.message,
      };
    }

    return { success: false, message: response.data.message };
  } catch (error: any) {
    console.error("Error:", error.message);
    return { success: false, message: "Failed to fetch data" };
  }
};

import React, { useEffect, useState } from "react";
import { Form } from "react-formio";
import { fetchRuntimeForm, getToken } from "./Apirequest";
import { Button, CircularProgress } from "@mui/material";
import { formioUploadOptions } from "./utils/FileService";

const FormPreview = ({ rowData, handleClose }) => {
  const [formData, setFormData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getForm = async (id) => {
    setLoading(true);
    try {
      const token = await getToken();
      const result = await fetchRuntimeForm(id, token);

      if (result) {
        setFormData(result?.data?.components);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getForm(process.env.REACT_APP_FORM_ID);
  }, []);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "95%" }}>
          {!loading && (
            <Form
              form={formData}
              submission={{ data: rowData }}
              options={{ ...formioUploadOptions }}
            />
          )}
        </div>
        {loading && <CircularProgress />}
        <Button
          variant="outlined"
          onClick={() => {
            // navigate("/results")
            handleClose();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default FormPreview;

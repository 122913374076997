import { createTheme, ThemeOptions } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";

const primary = "#016BEB";
const primaryLight = "#000000DD";
const secondary = "#000036";
const error = "#f17b7b";

// Define font sizes as constants
const FONT_SIZES = {
  SMALL: "0.8rem",
  MEDIUM: "0.9rem",
  LARGE: "1rem",
  XLARGE: "1.5rem",
  XXLARGE: "2rem",
};

// Extend ThemeOptions interface to include fontSizes
interface CustomThemeOptions extends ThemeOptions {
  fontSizes?: typeof FONT_SIZES;
}

declare module "@mui/system" {
  interface DefaultTheme extends Theme {
    fontSizes: typeof FONT_SIZES;
  }
}

export const INITIAL_THEME = createTheme({
  palette: {
    primary: {
      main: primary,
      light: primaryLight,
      contrastText: "#f8f9fa",
    },
    secondary: {
      main: secondary,
      contrastText: "#f8f9fa",
    },
    error: {
      main: error,
    },
  },
  typography: {
    fontFamily: "Barlow, Arial, sans-serif",
  },
  fontSizes: FONT_SIZES,
} as CustomThemeOptions); // Type assertion to CustomThemeOptions

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Colors from "../theme/colors.json";

interface TableColumn {
  id: string;
  label: string;
  align: "left" | "right" | "center";
  minWidth?: number;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  handleEdit: (e) => void;
  handleDelete: (e) => void;
  handleView: (e) => void;
  isEdit?: boolean;
  isDelete?: boolean;
  isView?: boolean;
}

const useStyles = makeStyles((theme) => ({
  customTableContainer: {
    maxHeight: "64vh",
    minWidth: 200,
    borderRadius: "0.6rem",
    // border: "1px solid grey",
    // overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4rem", // Initially hide the scrollbar
    },

    "&:hover": {
      "&::-webkit-scrollbar": {
        width: "0.4rem", // Adjust the width on hover
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: Colors.GRAY,
        borderRadius: 7,
      },
    },
  },
  customTableHeading: {
    fontWeight: 600,
    fontSize: theme.fontSizes.SMALL,
    padding: "0 0 0 0.8rem",
  },
  btnStyles: {
    fontSize: "8px",
    fontWeight: 300,
  },
  actionsWidth: {
    // minWidth: 200,
    padding: "0 0 0 0.8rem",
  },
  customTableCell: {
    fontSize: theme.fontSizes.SMALL,
    padding: "0 0 0 0.8rem",
  },
  iconStyles: {
    fontSize: "medium",
  },
}));

const CustomTable: React.FC<TableProps> = ({
  columns,
  data,
  handleEdit,
  handleDelete,
  handleView,
  isEdit,
  isDelete,
  isView,
}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.customTableContainer}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                className={classes.customTableHeading}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
            <TableCell
              className={`${classes.customTableHeading} ${classes.actionsWidth}`}
              align="center"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.customTableCell}
                >
                  {row[column.id]}
                </TableCell>
              ))}
              <TableCell align="center" className={classes.actionsWidth}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  // gap={2}
                >
                  {isEdit && (
                    <IconButton onClick={() => handleEdit(row)} size="medium">
                      <Tooltip title="Edit">
                        <EditIcon
                          color="primary"
                          className={classes.iconStyles}
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                  {isDelete && (
                    <IconButton onClick={() => handleDelete(row)} size="medium">
                      <Tooltip title="Delete">
                        <DeleteIcon
                          color="secondary"
                          className={classes.iconStyles}
                        />
                      </Tooltip>
                    </IconButton>
                  )}
                  {isView && (
                    <Button onClick={() => handleView(row)}>View</Button>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;

import { request, ResponseProps } from "../services/request";
import { DELETE_DOCUMENT, DOWNLOAD_DOCUMENT } from "../constants/constants";
import axios from "axios";
import { getToken } from "../Apirequest";

export const uploadDocument = async (url, data) => {
  try {
    const token = await getToken();

    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    const response = await axios.post(
      `${process.env.REACT_APP_GATEWAY_URL}${url}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.success) {
      return {
        success: response.data.success,
        message: response.data.message,
        data: response.data.data,
      };
    }
    return { success: false };
  } catch (error: any) {
    console.error("Error in uploadDocument:", error);
    return { success: false, message: error.message };
  }
};

export const deleteDocument = async (id) => {
  const apiEndpoint = `${sessionStorage.getItem(
    "gatewayURL"
  )}${DELETE_DOCUMENT}${id}`;
  const r: ResponseProps = (await request.delete(apiEndpoint)) as ResponseProps;

  if (r.success) {
    return { success: r.success, message: r.message, data: r.data };
  }

  return { success: false, message: r.message };
};

export const downloadDocument = async (id: string) => {
  const token = sessionStorage.getItem("react-token");
  const apiEndpoint = `${sessionStorage.getItem(
    "gatewayURL"
  )}${DOWNLOAD_DOCUMENT}${id}`;

  const response = await axios.get(apiEndpoint, {
    headers: {
      Authorization: `Bearer ${token}`,

      responseType: "blob", // VERY IMPORTANT 'arrayBuffer'
    },
  });

  return response;
};

export const openFile = async (id: string) => {
  let Token = sessionStorage.getItem("react-token");
  fetch(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/dms/v1/documents/download?id=${id}`,
    {
      method: "get",
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    }
  )
    .then((res) => res.blob())
    .then((blobData) => {
      const fileURL = URL.createObjectURL(blobData);
      //Open the URL on new Window
      const pdfWindow: any = window.open();
      pdfWindow.location.href = fileURL;
    });
};

export const downloadFile = async (id: string, fileName: string) => {
  let Token = await getToken();
  fetch(
    `${process.env.REACT_APP_GATEWAY_URL}/dms/v1/documents/download?id=${id}`,
    {
      method: "get",
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    }
  )
    .then((res) => res.blob())
    .then((blobData) => {
      const fileURL = URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    });
};

import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { INITIAL_THEME } from "./theme";
import Navigator from "./components/navigation/Navigator";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Dialog from "@mui/material/Dialog";

const App = () => {
  const getAppChildren = () => (
    <ThemeProvider theme={INITIAL_THEME}>
      <Provider store={store}>
        <div className="appBackgroundContainer">
          <div className="appContainer">
            {/* Removing Dialog is effecting application styles */}
            <Dialog open={false} />
            {/* <Wrapper> */}
            <Navigator />
            {/* <LoadSxpChat /> */}
            {/* </Wrapper> */}
            {/* <LandingPage /> */}
          </div>
        </div>
      </Provider>
    </ThemeProvider>
  );

  return <div>{getAppChildren()}</div>;
};

export default App;

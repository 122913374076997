import React from "react";

const Error = () => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      UnAuthorised to view
    </div>
  );
};

export default Error;

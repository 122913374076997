import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorPage from "../ErrorPage";
import LandingPage from "../../LandingPage";
import Results from "../../Results";
import FormPreview from "../../FormPreview";
import Error from "../../Error";

const ResultsRoute = () => {
  const [searchParams] = useSearchParams();
  const user = searchParams.get("user");
  const password = searchParams.get("password");

  if (
    user === process.env.REACT_APP_RESULT_USERNAME &&
    password === process.env.REACT_APP_RESULT_PASSWORD
  ) {
    return <Results />;
  }

  return <Error />;
};

const Navigator = () => {
  // const { filteredRoutes } = useSelector((state: any) => ({
  //   filteredRoutes: state.filteredRoutes,
  // }));

  return (
    <Routes>
      {/* {filteredRoutes.map((route) => (
        <Route
          key={route.key}
          path={route.path}
          element={<route.component />}
        />
      ))} */}
      <Route path="/" element={<LandingPage />} />
      <Route path="/results" element={<ResultsRoute />} />
      {/* <Route path="/preview" element={<FormPreview />} /> */}
      <Route path="/error" element={<Error />} />
    </Routes>
  );
};

export default Navigator;

import React, { useEffect } from "react";
import FormRender from "./FormRenderer";
import { getToken } from "./Apirequest";

const LandingPage = () => {
  const fetchToken = async () => {
    try {
      const token = await getToken();
      sessionStorage.setItem("token", token);
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      <FormRender />
    </div>
  );
};

export default LandingPage;

const CONSTANTS = {
  WELCOME_MSG: "welcome_msg",
  COMPONENT_LIST_MSG: "component_list_msg",
  DEFAULT_MSG: "default_msg",
  PROFILE_MSG: "profile_msg",
  SETTINGS: "settings",
  PROFILE: "profile",
  LOGOUT: "logout",
  THEME: "theme",
  SXP_TITLE: "Welcome to TSF React base project",
  SXP_SUBTITLE: "Service eXperience Platform",
  SXP_DEFAULT_MSG: "Hi, Welcome To React Template Project",
  VERSION_ID: "WORKING",
  COULDNT_UPLOAD_DOC: "Could not upload Document",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  BUSINESS_USER: "business-user",
  SELECT_START_END_DATE: "Select start date and end date",
  USER_EMAIL: "user-email",
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  USER_ID: "user-id",
  REACT_TOKEN: "react-token",
  USER_TYPE: "user-type",
  COUNTRY: "country",
  I18NEXTLNG: "i18nextLng",
};

export default CONSTANTS;

export const PRODUCT_CODES_FORM_ID = "1164618994846031872";
export const PRODUCT_CATEGORY_FORM_ID = "1166254273382080512";
export const PRODUCT_CAVORAGES_LIMITIFANY_FORM_ID = "1173841817539993600";

export const FireLossofProfit_FormId = "1166415611823677440";
export const StandardFireandSpecialPerils_FormId = "1166419387787796480";

export const Proposal_Specific_Coverages_LimitIfany = "1178617936301568000";

export const INSURANCE_COMPANIES_FORM_ID = "1168445864888786944";

export const INSURER_PORTAL_ACCESS_URLS = "1199609049560199168";

//contact registration
export const CLIENT_REGISTRATION_FORM_ID = "1204713369670594560";

export const INSURANCE_COMPANY_EMPLOYEE_FORM_ID = "1205443812292374528";

export const INTERNAL_EMPLOYEE_FORM_ID = "1205444077359804416";
//

export const Product_Code_Form_Constants = {
  productCode: "Please enter product code",
  productCategory: "Please enter product category",
  productTitle: "Please enter the product title",
  productStatus: "Please select the product status",
  startDate: "Please enter start date",
  endDate: "Please enter end date",
  description: "Please enter the description",
};

export const MODAL_ACTIONS = {
  CREATE: "Create",
  EDIT: "Edit",
  DELETE: "Delete",
};

export const ADD_OPTIONS_UNDERWRITER = {
  ADD_COVERAGECLAUSE: "CoverageClause",
  ADD_LIMITIFANY: "LimitIfAny",
};

export const USER_GROUPS = {
  ADMIN: "/insurpro-admin",
  BROKER: "/insurpro-sales",
  UNDERWRITER: "/insurpro-underwriter",
  ACCOUNTANT: "/insurpro-accountant",
};

export const PROPOSAL_STATUS = {
  DRAFT: "Proposal Draft",
  UNDERREVIEW: "Under Review",
  RFQ_GENERATED: "RFQ Generated",
  QCR_GENERATED: "QCR Generated",
  QCRDRAFT: "QCR Draft",
  CLOSEDWON: "Closed Won",
  CLOSEDLOST: "Closed Lost",
};

export const FORMS_ERROR_MSG =
  "Form is not validated, kindly fill all the required details";

export const PREVIEW_ENUMS = {
  PREVIEW_RFQ: "rfqPreview",
  PREVIEW_QCR: "qcrPreview",
};

export const GENERATE_ENUMS = {
  GENERATE_RFQ: "rfqGenerate",
  GENERATE_QCR: "qcrGenerate",
};

export const FORM_HIDDEN_KEYS = {
  disableAddingRemovingRows: "disableAddingRemovingRows",
  relationShipManager: "Relationship Manager",
  serviceManager: "Service Manager",
  enclosures: "ENCLOSURES",
  remarks: "REMARKS",
};

export const QCR_DISCLAIMER =
  "The quotes provided are based on the information you've supplied (as mentioned above), and the terms may be adjusted if there are any alterations to the material information provided.";

export const Table_Error_Label = "No Proposal Found, Please create one.";
export const Create_Proposal_Error_Label =
  "Please select the product category and product title to retrieve the proposal.";

export const DELETE_DOCUMENT = "/dms/v1/documents?id=";
export const DOWNLOAD_DOCUMENT = "/dms/v1/documents/download?id=";

export const DECISION_MENUITEMS = [
  {
    title: "Yes",
    value: "yes",
  },
  {
    title: "No",
    value: "no",
  },
];

export const DECISION_ENUMS = {
  YES: "yes",
  NO: "no",
};

export const GET_RECORDS_LIMIT = 10000;

export const PREVIEW_PLACEMENT_ENUMS = {
  PREVIEW_PLACEMENT_LETTER: "PreviewPlacementLetter",
  PREVIEW_PLACEMENT_SLIP: "PreviewPlacementSlip",
};

export const PREMIUM_BREAK_UP_SECTIONS = {
  NET_PREMIUM: "netPremium",
  MOTORTPPREMIUM: "motorTpPremium",
};

export const COMMISSION_INFO_SECTIONS = {
  NET_PREMIUM: "netPremium",
  MOTORTPPREMIUM: "motorTpPremium",
};

export const CONFIRMATION_TITLE =
  "Are you sure you want to validate this section?";

export const CONFIRMATION_CONTENT =
  "Selecting 'Yes' will confirm the validation of the section and lock in the values";

export const COMMISSION_INFO_VALIDATIONS = {
  Commission_Amount: "commissionAmount",
  Reward_Amount: "rewardAmount",
  Reconciliation_Statement_From_Insurer: "reconciliationStatementFromInsurer",
  Bank_Reconciliation_Statement: "bankReconciliationStatement",
};

export const adminCategoriesToFetch = [
  "overall",
  "employee",
  "insurer",
  "category",
];

export const userCategoriesToFetch = ["overall"];

export const Gross_Premium_Multiplier = 1.18;

const handleStatusLevel = (status) => {
  switch (status) {
    case "Proposal Draft":
      return 1;
    case "Under Review":
      return 2;
    case "QCR Draft":
      return 3;
    case "RFQ Generated":
      return 4;
    case "QCR Generated":
      return 5;
    case "Closed Won":
      return 6;
    case "Closed Lost":
      return 6;
    default:
      return 0;
  }
};

export const handleStepStatus = (reduxStatus: string, status: string) => {
  if (!reduxStatus || !status) return status;

  const reduxLevel = handleStatusLevel(reduxStatus);
  const statusLevel = handleStatusLevel(status);

  switch (true) {
    case reduxLevel > statusLevel:
      return reduxStatus;
    case reduxLevel === statusLevel:
      return status;
    default:
      return status;
  }
};

export const PROPOSAL_CONFIRMATION_TITLE =
  "Are you sure you want to delete this proposal?";

export const PROPOSAL_CONFIRMATION_CONTENT = "";

export const Chart_Errors = {
  productwisePremium_error: "No Chart Data Found",
  commission_error: "No Chart Data Found",
  premium_error: "No Chart Data Found",
  proposal_error: "No Chart Data Found",
  serviceManger_error: "No Chart Data Found",
  renewals_error: "No Renewal Data Found",
};

export const handleValueRoundOff = (value) => {
  return Math.round(parseFloat(value));
};

export const COMMISSION_STATUS_LIST = [
  {
    title: "Outstanding",
    value: "outstanding",
  },
  {
    title: "Reconciliation Done",
    value: "reconciliationDone",
  },
  {
    title: "Discrepancy",
    value: "discrepancy",
  },
  {
    title: "Invoice Generated",
    value: "invoiceGenerated",
  },
  {
    title: "Commission Settled",
    value: "commissionSettled",
  },
];

export const REWARD_STATUS_LIST = [
  {
    title: "Outstanding",
    value: "outstanding",
  },
  {
    title: "Reconciliation Done",
    value: "reconciliationDone",
  },
  {
    title: "Discrepancy",
    value: "discrepancy",
  },
  {
    title: "Invoice Generated",
    value: "invoiceGenerated",
  },
  {
    title: "Reward Settled",
    value: "rewardSettled",
  },
];

export const Btn_Action = {
  SAVE: "save",
  NEXT: "next",
};

export const Historical_Data_Note =
  "Note: Please upload the CSV or XLSX file of your existing data";

import { PUSH_NOTIFICATION } from "../constants/actions";

// Action Type
export const LOADER = "LOADER";
export const ACCESS_TOEKN = "ACCESS_TOEKN";
export const PROPOSAL_DATA = "PROPOSAL_DATA";
export const ROUTES_FILTERED = "ROUTES_FILTERED";
export const USER_GROUP = "USER_GROUP";
export const PROPOSAL_CART = "PROPOSAL_CART";
export const USER_ID = "USER_ID";
export const PROPOSAL_CREATED_ID = "PROPOSAL_CREATED_ID";

// Action
export const setLoader = (loading) => {
  return {
    type: LOADER,
    payload: loading,
  };
};

export const setAnonymousUser = (data) => {
  return {
    type: LOADER,
    payload: data,
  };
};

export const pushNotification = (data) => {
  return { type: PUSH_NOTIFICATION, payload: data };
};

export const setAccessToken = (token) => {
  return {
    type: ACCESS_TOEKN,
    payload: token,
  };
};

export const setProposalData = (data) => {
  return {
    type: PROPOSAL_DATA,
    payload: data,
  };
};

export const setFilteredRoutes = (data) => {
  return {
    type: ROUTES_FILTERED,
    payload: data,
  };
};

export const setUserGroup = (data) => {
  return {
    type: USER_GROUP,
    payload: data,
  };
};

export const addProposalToCart = (data) => {
  return {
    type: PROPOSAL_CART,
    payload: data,
  };
};

export const setUserId = (data) => {
  return {
    type: USER_ID,
    payload: data,
  };
};

export const setProposalCreatedId = (data) => {
  return {
    type: PROPOSAL_CREATED_ID,
    payload: data,
  };
};

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { fetchRuntimeForm, getToken, postRuntimeFormData } from "./Apirequest";
import { Form } from "react-formio";
import { pushNotification } from "./redux/actions";
import CONSTANTS, { FORMS_ERROR_MSG } from "./constants/constants";
import { useDispatch } from "react-redux";
import { formioUploadOptions } from "./utils/FileService";

const FormRender = () => {
  const formRef = useRef<any>();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // const checkFormSubmission = async () => {
  //   if (formRef?.current) {
  //     console.log(formRef?.current);
  //     formRef.current.formio.submit();
  //     setIsSubmitted(true);
  //   } else {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         type: CONSTANTS?.ERROR,
  //         message: FORMS_ERROR_MSG,
  //       })
  //     );
  //     return;
  //   }
  // };

  const checkFormSubmission = async () => {
    if (formRef?.current) {
      const formio = formRef.current.formio;

      try {
        // Trigger form validation
        const isValid = await formio.checkValidity(
          formio.submission.data,
          true
        );

        if (isValid) {
          // Submit the form if validation passes
          await formio.submit();
          setIsSubmitted(true);
        } else {
          // Notify the user about validation errors
          formio.setPristine(false); // Highlight invalid fields
          dispatch(
            pushNotification({
              isOpen: true,
              type: CONSTANTS.ERROR,
              message: "Please fill in all required fields.",
            })
          );
        }
      } catch (error) {
        console.error("Form submission error:", error);
        dispatch(
          pushNotification({
            isOpen: true,
            type: CONSTANTS.ERROR,
            message: FORMS_ERROR_MSG,
          })
        );
      }
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          type: CONSTANTS.ERROR,
          message: FORMS_ERROR_MSG,
        })
      );
    }
  };

  const getForm = async (id) => {
    setLoading(true);
    try {
      const token = await getToken();
      const result = await fetchRuntimeForm(id, token);

      if (result) {
        setFormData(result?.data?.components);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleFormSubmit = async (submission) => {
    const body: any = {
      formId: process.env.REACT_APP_FORM_ID,
      formData: {
        ...submission?.data,
      },
    };
    const token = await getToken();
    const response: any = await postRuntimeFormData(body, token);
    if (response?.success) {
      setIsSubmitted(true);
      dispatch(
        pushNotification({
          isOpen: true,
          type: CONSTANTS.SUCCESS,
          message: response?.message,
        })
      );
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          type: CONSTANTS.ERROR,
          message: response?.message,
        })
      );
    }
  };

  useEffect(() => {
    getForm(process.env.REACT_APP_FORM_ID);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <div style={{ fontSize: "20px", fontWeight: "600" }}>Survey Form</div> */}
      <div style={{ width: "95%" }}>
        {!loading && !isSubmitted && (
          <Form
            form={formData}
            ref={formRef}
            onSubmit={(submission) => handleFormSubmit(submission)}
            options={{ ...formioUploadOptions }}
          />
        )}
      </div>
      {loading && !isSubmitted && <CircularProgress />}
      {!loading && !isSubmitted && (
        <Button
          variant="outlined"
          onClick={() => {
            checkFormSubmission();
          }}
        >
          Submit
        </Button>
      )}
      {isSubmitted && (
        <Card
          style={{
            width: "80%",
            textAlign: "center",
            padding: "20px",
            color: "#fff",
            background: "#088F8F",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Submitted Successfully!
            </Typography>
            <Typography variant="body1">
              Thank you for completing the survey.
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default FormRender;
